import { Fragment, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';



// home pages
const HomeFashion = lazy(() => import("./pages/home/Belt"));
const HomeFashionMicro = lazy(() => import("./pages/home/MicroFiber_Ties"));
const HomeFashionLongTies = lazy(() => import("./pages/home/Long_Ties"));
const HomeFashionLogoSingle = lazy(() => import("./pages/home/Single_Logo_Ties"));
const HomeFashionLogoTies = lazy(() => import("./pages/home/Logo_Ties"));
const HomeFashionStripesTies = lazy(() => import("./pages/home/Stripes_Ties"));
const HomeFashionDotsPlain = lazy(() => import("./pages/home/Dots_Plain_Ties"));
const HomeFashionAllOver = lazy(() => import("./pages/home/All_Over_Logo_Ties"));
const HomeFashionPlated = lazy(() => import("./pages/home/Plated_Button"));
const HomeFashionTrackSuit = lazy(() => import("./pages/home/TrackSuit"));
const HomeFashionSweater = lazy(() => import("./pages/home/Sweater"));
const HomeFashionHoody = lazy(() => import("./pages/home/Hoody"));
const HomeFashionBeltChart = lazy(() => import("./pages/home/Belt_Chart"));
const HomeFashionTieChart = lazy(() => import("./pages/home/Tie_Chart"));
const HomeFashionSockChart = lazy(() => import("./pages/home/Socks_Chart"));
const HomeFashionJacket = lazy(() => import("./pages/home/Jacket"));
const HomeFashionGallery = lazy(() => import("./pages/home/Event_Gallery"));
const HomeFashionSizeChart = lazy(() => import("./pages/home/Size_Chart"));
const HomeFashionEpoxyLaminated = lazy(() => import("./pages/home/Epoxy_Laminated_Button"));

const HomeFashionStripesMicro = lazy(() => import("./pages/home/Stripes_MicroFiber_Ties"));
const HomeFashionSatins = lazy(() => import("./pages/home/Satin_Print_Ties"));
const HomeFashionShimmer = lazy(() => import("./pages/home/Shimmer_Ties"));
const HomeFashionZipper = lazy(() => import("./pages/home/Zipper_Ties"));
const HomeFashionDesign = lazy(() => import("./pages/home/Ties_Design"));
const HomeFashionDemo = lazy(() => import("./pages/home/Demo_Videos"));
const HomeFashionUniform = lazy(() => import("./pages/home/Uniform"));
const HomeFashionTie = lazy(() => import("./pages/home/Ties"));
const HomeFashionTshirt = lazy(() => import("./pages/home/Tshirt"));
const HomeFashionButton = lazy(() => import("./pages/home/Buttons"));
const HomeFashionSocks = lazy(() => import("./pages/home/Socks"));
const HomeFashionBags = lazy(() => import("./pages/home/Bags"));
const HomeFashionCups = lazy(() => import("./pages/home/Cups_Trophies"));
const HomeFashionMementos = lazy(() => import("./pages/home/Mementos"));
const HomeFashionKey = lazy(() => import("./pages/home/Key_Chains"));
const HomeFashionPrinted = lazy(() => import("./pages/home/Prinited_Flap_Buckle"));
const HomeFashionPlastic = lazy(() => import("./pages/home/Plastic_Flap_Buckle"));
const HomeFashionDial = lazy(() => import("./pages/home/Dial_Buckle"));
const HomeFashionHelmet = lazy(() => import("./pages/home/Helmet_Buckle"));
const HomeFashionJoint = lazy(() => import("./pages/home/Joint_Buckle"));
const HomeFashionAnchor = lazy(() => import("./pages/home/Anchor_Buckle"));
const HomeFashionHook = lazy(() => import("./pages/home/Hook_Buckle"));
const HomeFashionKapre = lazy(() => import("./pages/home/Kapre_Buckle"));
const HomeFashionBison = lazy(() => import("./pages/home/Plastic_Bison_Buckle"));
const HomeFashionEmbossed = lazy(() => import("./pages/home/Embossed_Flap_Buckle"));
const HomeFashionMedals = lazy(() => import("./pages/home/Medals"));
const HomeFashionBadges = lazy(() => import("./pages/home/Brooch"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionOneNew = lazy(() => import("./pages/home/Pin_Buckle_Belt"));
const HomeFashionTapeChoice = lazy(() => import("./pages/home/Tape_Choices_Belt"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree")); 
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSixLoco = lazy(() => import("./pages/home/HomeFashionSixLoco"));
const HomeFashionSeven = lazy(() => import("./pages/home/Webbing_Belts"));
const HomeFashionSevenNew = lazy(() => import("./pages/home/Itilian_Reversible_Belt"));

const HomeFashionEight = lazy(() => import("./pages/home/Satin_Belt"));
const HomeFashionEightNew = lazy(() => import("./pages/home/premium_laminate"));
const HomeFashionEightIron = lazy(() => import("./pages/home/Iron_Die_Medal"));
const HomeFashionEightPicture = lazy(() => import("./pages/home/Picture_Laminated_Zinc_Medal"));
const HomeFashionEightEconomy = lazy(() => import("./pages/home/Bakelite_Medal"));
const HomeFashionEightLapel = lazy(() => import("./pages/home/logo_brooch"));
const HomeFashionEightLapelPin = lazy(() => import("./pages/home/name_tabs"));
const HomeFashionEightBadges = lazy(() => import("./pages/home/investiture_brooch"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
const HomeFurnitureThree = lazy(() =>
  import("./pages/home/HomeFurnitureThree")
);
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
const HomeFurnitureSeven = lazy(() =>
  import("./pages/home/HomeFurnitureSeven")
);
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);
const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);
const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
const HomePlants = lazy(() => import("./pages/home/HomePlants"));
const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
const HomeOrganicFoodTwo = lazy(() =>
  import("./pages/home/HomeOrganicFoodTwo")
);
const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
const HomeMedicalEquipment = lazy(() =>
  import("./pages/home/HomeMedicalEquipment")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() =>
  import("./pages/home/HomeBlackFridayTwo")
);
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/Key_Chain"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/about"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  useEffect(() => {
    document.title = "";
  }, []);
  return (
    <Fragment>
      
      <Router>
        <ScrollToTop>
         
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeFashionThree/>}
              />

              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/Belt"}
                element={<HomeFashion/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Demo_Videos"}
                element={<HomeFashionDemo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Uniform"}
                element={<HomeFashionUniform/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Ties"}
                element={<HomeFashionTie/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Buttons"}
                element={<HomeFashionButton/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Socks"}
                element={<HomeFashionSocks/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Bags"}
                element={<HomeFashionBags/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Cups_Trophies"}
                element={<HomeFashionCups/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Mementos"}
                element={<HomeFashionMementos/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Key_Chains"}
                element={<HomeFashionKey/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Sweater"}
                element={<HomeFashionSweater/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Hoody"}
                element={<HomeFashionHoody/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Size_Chart"}
                element={<HomeFashionSizeChart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Jacket"}
                element={<HomeFashionJacket/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Prinited_Flap_Buckle"}
                element={<HomeFashionPrinted/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Plastic_Flap_Buckle"}
                element={<HomeFashionPlastic/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Dial_Buckle"}
                element={<HomeFashionDial/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Helmet_Buckle"}
                element={<HomeFashionHelmet/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Single_Logo_Ties"}
                element={<HomeFashionLogoSingle/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Joint_Buckle"}
                element={<HomeFashionJoint/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Kapre_Buckle"}
                element={<HomeFashionKapre/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Anchor_Buckle"}
                element={<HomeFashionAnchor/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Hook_Buckle"}
                element={<HomeFashionHook/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Plastic_Bison_Buckle"}
                element={<HomeFashionBison/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Embossed_Flap_Buckle"}
                element={<HomeFashionEmbossed/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Medals"}
                element={<HomeFashionMedals/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Brooch"}
                element={<HomeFashionBadges/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Event_Gallery"}
                element={<HomeFashionGallery/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Belt_Chart"}
                element={<HomeFashionBeltChart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Tie_Chart"}
                element={<HomeFashionTieChart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Socks_Chart"}
                element={<HomeFashionSockChart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-two"}
                element={<HomeFashionTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Pin_Buckle_Belt"}
                element={<HomeFashionOneNew/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Tape_Choices_Belt"}
                element={<HomeFashionTapeChoice/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Epoxy_Laminated_Button"}
                element={<HomeFashionEpoxyLaminated/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/MicroFiber_Ties"}
                element={<HomeFashionMicro/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/TrackSuit"}
                element={<HomeFashionTrackSuit/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Satin_Print_Ties"}
                element={<HomeFashionSatins/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Shimmer_Ties"}
                element={<HomeFashionShimmer/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Ties_Design"}
                element={<HomeFashionDesign/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Tshirt"}
                element={<HomeFashionTshirt/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Zipper_Ties"}
                element={<HomeFashionZipper/>}
              />
            
              <Route
                path={process.env.PUBLIC_URL + "/Dots_Plain_Ties"}
                element={<HomeFashionDotsPlain/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Long_Ties"}
                element={<HomeFashionLongTies/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Stripes_Ties"}
                element={<HomeFashionStripesTies/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Plated_Button"}
                element={<HomeFashionPlated/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Stripes_MicroFiber_Ties"}
                element={<HomeFashionStripesMicro/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Logo_Ties"}
                element={<HomeFashionLogoTies/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/All_Over_Logo_Ties"}
                element={<HomeFashionAllOver/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-three"}
                element={<HomeFashionThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-four"}
                element={<HomeFashionFour/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-five"}
                element={<HomeFashionFive/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-six"}
                element={<HomeFashionSix/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-six-loco"}
                element={<HomeFashionSixLoco/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Webbing_Belts"}
                element={<HomeFashionSeven/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Itilian_Reversible_Belt"}
                element={<HomeFashionSevenNew/>}
              />
           
              <Route
                path={process.env.PUBLIC_URL + "/Satin_Belt"}
                element={<HomeFashionEight/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/premium_laminate"}
                element={<HomeFashionEightNew/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Iron_Die_Medal"}
                element={<HomeFashionEightIron/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Picture_Laminated_Zinc_Medal"}
                element={<HomeFashionEightPicture/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/Bakelite_Medal"}
                element={<HomeFashionEightEconomy/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/logo_brooch"}
                element={<HomeFashionEightLapel/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/name_tabs"}
                element={<HomeFashionEightLapelPin/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/investiture_brooch"}
                element={<HomeFashionEightBadges/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-kids-fashion"}
                element={<HomeKidsFashion/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cosmetics"}
                element={<HomeCosmetics/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture"}
                element={<HomeFurniture/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-two"}
                element={<HomeFurnitureTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-three"}
                element={<HomeFurnitureThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-four"}
                element={<HomeFurnitureFour/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-five"}
                element={<HomeFurnitureFive/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-six"}
                element={<HomeFurnitureSix/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-furniture-seven"}
                element={<HomeFurnitureSeven/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics"}
                element={<HomeElectronics/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-two"}
                element={<HomeElectronicsTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-electronics-three"}
                element={<HomeElectronicsThree/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store"}
                element={<HomeBookStore/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-book-store-two"}
                element={<HomeBookStoreTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-plants"}
                element={<HomePlants/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop"}
                element={<HomeFlowerShop/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-flower-shop-two"}
                element={<HomeFlowerShopTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food"}
                element={<HomeOrganicFood/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-organic-food-two"}
                element={<HomeOrganicFoodTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-onepage-scroll"}
                element={<HomeOnepageScroll/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-grid-banner"}
                element={<HomeGridBanner/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-auto-parts"}
                element={<HomeAutoParts/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-cake-shop"}
                element={<HomeCakeShop/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-handmade"}
                element={<HomeHandmade/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-pet-food"}
                element={<HomePetFood/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-medical-equipment"}
                element={<HomeMedicalEquipment/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-christmas"}
                element={<HomeChristmas/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday"}
                element={<HomeBlackFriday/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-black-friday-two"}
                element={<HomeBlackFridayTwo/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-valentines-day"}
                element={<HomeValentinesDay/>}
              />

              {/* Shop pages */}
              <Route
                path={process.env.PUBLIC_URL + "/Key_Chain"}
                element={<ShopGridStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                element={<ShopGridFilter/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                element={<ShopGridTwoColumn/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                element={<ShopGridNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                element={<ShopGridFullWidth/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                element={<ShopGridRightSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                element={<ShopListStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                element={<ShopListFullWidth/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                element={<ShopListTwoColumn/>}
              />

              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                element={<ProductTabLeft/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                element={<ProductTabRight/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                element={<ProductSticky/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                element={<ProductSlider/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                element={<ProductFixedImage/>}
              /> 

              {/* Blog pages */}
              <Route
                path={process.env.PUBLIC_URL + "/blog-standard"}
                element={<BlogStandard/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                element={<BlogNoSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                element={<BlogRightSidebar/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/blog-details-standard"}
                element={<BlogDetailsStandard/>}
              /> 

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister/>}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare/>}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout/>}
              /> 

              <Route path="*" element={<NotFound/>} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
      </Fragment>
  );
};

export default App;